import React, { Component } from "react";

export class Gallery extends Component {
  render() {
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Comprar</h2>
            <p>
              Clique no produto que deseja.
            </p>
          </div>
          <div className="row">
            <div className="portfolio-items">
            <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/01-large.jpg"
                      title="Aerosol 1 unidade"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Aerosol 1 unidade - R$33,00</h4>
                      </div>
                      <img
                        src="img/portfolio/aerosol-1un.jpg"
                        className="img-responsive"
                        alt="Aerosol 1 unidade"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/01-large.jpg"
                      title="Aerosol 2 unidades"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Aerosol 2 unidades - R$70,00</h4>
                      </div>
                      <img
                        src="img/portfolio/aerosol-2un.png"
                        className="img-responsive"
                        alt="Aerosol 2 unidades"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/02-large.jpg"
                      title="Aerosol 3 unidades"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Aerosol 3 unidades - R$93,00</h4>
                      </div>
                      <img
                        src="img/portfolio/aerosol-3un.jpg"
                        className="img-responsive"
                        alt="Aerosol 3 unidades"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/03-large.jpg"
                      title="Aerosol 6 unidades"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Aerosol 6 unidades - R$ 174,00</h4>
                      </div>
                      <img
                        src="img/portfolio/aerosol-6un.jpg"
                        className="img-responsive"
                        alt="Aerosol 6 unidades"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/04-large.jpg"
                      title="Aerosol 9 unidades"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Aerosol 9 unidades - R$234,00</h4>
                      </div>
                      <img
                        src="img/portfolio/aerosol-9un.jpg"
                        className="img-responsive"
                        alt="Aerosol 9 unidades"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/05-large.jpg"
                      title="Galão 5L"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Galão 5L 1 unidade - R$235,00</h4>
                      </div>
                      <img
                        src="img/portfolio/galao-1un.jpg"
                        className="img-responsive"
                        alt="Galão 5 litros 1 unidade"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
